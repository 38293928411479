<template>
  <section class="bando-head">
    <h1>Vacations des Facteurs Services Experts</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">
        Agenda des vacations FSE pour l'établissement
        <span class="yellow">{{ etablissement.nom }}</span>
      </h2>
      <h3>
        <img src="@/assets/img/icon-zones.png" alt="Zones de vacations" />
        <span>
          Zones de vacations
          <ul>
            <!-- Class "active" sur zone sélectionnée -->
            <li>
              <a href="" @click.prevent="selectZv($event)" class="btn active">
                Toutes les zones
              </a>
            </li>
            <li v-for="zoneVacation in zonesVacations" :key="zoneVacation">
              <a href="" @click.prevent="selectZv($event)" class="btn">
                {{ zoneVacation.nom }}
              </a>
            </li>
          </ul>
        </span>
      </h3>

      <div class="bloc-calendar">
        <div id="calendar"></div>

        <div class="fse-rattaches">
          <h3 class="align-items-center">
            <img src="@/assets/img/icon-fse.png" alt="FSE rattachés" />
            FSE rattachés
          </h3>
          <div class="d-flex" v-for="fse in fses" :key="fse">
            <div
              class="fse"
              :style="'background: ' + fse.couleur + ';'"
              :data-color="fse.couleur"
            >
              <router-link :to="{ name: 'FicheFSE', params: { id: fse.uuid } }">
                {{ fse.prenom }} {{ fse.nom }}
              </router-link>
            </div>
            <a href="#" class="view" title="Afficher sur l'agenda">
              <i class="fal fa-calendar-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import axios from "axios";

const FullCalendar = window.FullCalendar;
// const $ = window.$;

export default {
  name: "AgendaVacations",
  components: {},
  data() {
    return {
      etablissement: {
        adresse: [
          {
            address_line4_number: "",
            address_line4_name: "",
            address_line6_zipcode: "",
            address_line6_city: ""
          }
        ]
      },
      fses: [],
      zonesVacations: []
    };
  },
  created() {
    const route = useRoute();
    axios
      .all([
        axios.get(
          process.env.VUE_APP_API + "/etablissement/" + route.params.id
        ),
        axios.get(process.env.VUE_APP_API + "/fse/list"),
        axios.get(process.env.VUE_APP_API + "/zone-vacation/list")
      ])
      .then(
        axios.spread((...responses) => {
          this.etablissement = responses[0].data;
          this.fses = responses[1].data;
          this.zonesVacations = responses[2].data;
        })
      )
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  mounted() {
    var Calendar = FullCalendar.Calendar;
    var calendarEl = document.getElementById("calendar");

    // initialize the calendar
    // -----------------------------------------------------------------
    var calendar = new Calendar(calendarEl, {
      initialView: "dayGridMonth",
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay"
      },
      locale: "fr",
      editable: true,
      displayEventTime: true
    });

    calendar.render();
  },
  methods: {
    selectZv(e) {
      document.querySelectorAll("a.btn").forEach(node => {
        node.classList.remove("active");
      });
      e.srcElement.classList.add("active");
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  h3 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;

    @media screen and (min-width: 992px) {
      align-items: center;
    }

    img {
      margin-right: 1.5rem;
    }

    ul {
      display: flex;
      margin: 0.5rem 0 0;
      padding: 0;
      flex-wrap: wrap;

      li {
        list-style: none;
        margin: 0 1rem 0.5rem 0;
      }

      .btn {
        background: var(--grey);
        color: white;
        font-size: 1.4rem;
        padding: 0.75rem 3rem;

        &:hover,
        &.active {
          background: var(--navy);
        }
      }
    }
  }

  .bloc-calendar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (min-width: 992px) {
      flex-direction: row;
    }

    #calendar {
      order: 2;

      @media screen and (min-width: 992px) {
        order: 1;
        min-width: 75%;
        flex-basis: 75%;
        padding-right: 6rem;
      }
    }

    .fse-rattaches {
      margin-bottom: 2rem;
      order: 1;

      @media screen and (min-width: 992px) {
        min-width: 25%;
        flex-basis: 25%;
        margin-bottom: 0;
        order: 2;
      }

      h4 {
        background: var(--navy);
        border-radius: 0.5rem;
        color: white;
        font-size: 1.4rem;
        margin: 0 0 3rem;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      .fse {
        margin-bottom: 1rem;
        min-width: 70%;
      }

      a {
        color: black;

        &:hover {
          text-decoration: none;
        }

        &.view {
          margin: 0.5rem 0 0 1rem;

          &:hover {
            color: var(--yellow);
          }

          i {
            font-size: 2.4rem;
          }
        }
      }
    }
  }
}
</style>
